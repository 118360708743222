/* eslint-disable react/prop-types */
import React from 'react';

const yesNaOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'na', label: 'N/A' },
];

const yesNoOptions = [
  { value: 'yes', label: 'Yes' },
  { value: 'no', label: 'No' },
];

export default {
  types: {
    form: {

      renderers: {
        inline: ({ node }) => <>{node.title} - v{node.version}</>,
      },

      selections: {
        default: `
          id name version versionDate title updatedAt createdAt data 
          jobs {id job{id title}}
          files(order_by: {file: {updatedAt: desc}}) {id file{id name url mimeType}} 
          signatures
        `,
      },

      searchFields: `title data.owner data.cuttingPermit data.contractor data.block`,

      fields: {
        name: {
          type: 'String',
          validation: {
            required: true,
          },
        },

        version: {
          type: 'String',
          validation: {
            required: true,
          },
        },

        versionDate: {
          type: 'Date',
          validation: {
            required: true,
          },
        },

        title: {
          type: 'String',
          validation: {
            required: true,
          },
        },

        files: {
          type: 'file',
          hasMany: true,
        },

        signatures: {
          type: 'signature',
          hasMany: true,
          embedded: true,
        },

        data: {
          type: 'Json',
        },

        jobs: {
          type: 'job',
          hasMany: true,
          validation: { required: false },
        },

        // Change Order
        'data.changeOrder.date': { type: 'String', validation: { required: true } },
        'data.changeOrder.description': { type: 'String', validation: { required: true } },
        'data.changeOrder.files': { type: 'file', label: 'Photos & Files', hasMany: true, embedded: true, validation: { required: false } },

        /* Daily Safety */
        'data.dailySafety.jobLocation': { type: 'String', validation: { required: true }, app: { copyForward: true } },
        'data.dailySafety.date': { type: 'String', validation: { required: true }, app: { copyForward: false } },
        'data.dailySafety.firstAidAttendent': { type: 'String', validation: { required: true }, app: { copyForward: true } },
        'data.dailySafety.emergencyPhone': { type: 'String', validation: { required: true }, app: { copyForward: true } },
        'data.dailySafety.daysObjectives': { type: 'String', validation: { required: true }, app: { copyForward: true } },
        'data.dailySafety.safeWorkProcedures': { type: 'String', validation: { required: true }, app: { copyForward: true } },
        'data.dailySafety.ppe.hardHats': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.ppe.hearingProtection': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.ppe.respiratorFit': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.ppe.safetyGlasses': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.ppe.respirator': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.ppe.cleanShaven': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.ppe.safetyBootes': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.ppe.rainGear': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.ppe.signageTape': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.ppe.gloves': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.ppe.fullFacePar': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.ppe.other': { type: 'String', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.siteRespiratoryRequirements': { type: 'String', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.docs.hazMatSurvey': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.docs.riskAssessment': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.docs.safeWorkProcedures': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },
        'data.dailySafety.docs.whmis': { type: 'Boolean', validation: { required: false }, app: { copyForward: true } },

        /* Steep Slope */
        'data.date': { type: 'String', label: 'Date', validation: { required: true } },
        'data.owner': { type: 'String', label: 'License/Owner', validation: { required: false } },
        'data.cuttingPermit': { type: 'String', label: 'Cutting Permit', validation: { required: false } },
        'data.contractor': { type: 'String', label: 'Contractor', validation: { required: false } },
        'data.block': { type: 'String', label: 'Block', validation: { required: false } },
        'data.site': { type: 'String', label: 'Site/Location within Block', validation: { required: false } },
        'data.infoSupplied': { type: 'String', options: yesNoOptions, label: 'Appropriate Information Supplied by Owner (map, slope %, hazards) ', validation: { required: true } },
        'data.assessorQualified': { type: 'String', options: yesNoOptions, label: 'Assessor Qualified to Conduct Steep Slope Assessment', validation: { required: true } },

        'data.slope.tracked': { type: 'String', validation: { required: true } },
        'data.slope.trackedComments': { type: 'String', validation: { required: false } },
        'data.slope.wheeled': { type: 'String', validation: { required: true } },
        'data.slope.wheeledComments': { type: 'String', validation: { required: false } },
        'data.slope.unstable': { type: 'String', validation: { required: true } },
        'data.slope.unstableComments': { type: 'String', validation: { required: false } },
        'data.slope.roughness': { type: 'String', validation: { required: true } },
        'data.slope.roughnessComments': { type: 'String', validation: { required: false } },

        'data.prework.map': { type: 'String', options: yesNaOptions, validation: { required: true } },
        'data.prework.mapProcedures': { type: 'String', validation: { required: false } },
        'data.prework.tree': { type: 'String', options: yesNaOptions, validation: { required: true } },
        'data.prework.treeProcedures': { type: 'String', validation: { required: false } },
        'data.prework.approach': { type: 'String', options: yesNaOptions, validation: { required: true } },
        'data.prework.approachProcedures': { type: 'String', validation: { required: false } },
        'data.prework.operations': { type: 'String', options: yesNaOptions, validation: { required: true } },
        'data.prework.operationsProcedures': { type: 'String', validation: { required: false } },

      },

    },
  },
};
