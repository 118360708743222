import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography, IconButton, useMediaQuery, Button, Menu, MenuItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import { Add as AddIcon,
  ChevronRight as ForwardIcon,
  ChevronLeft as BackIcon,
  Reorder as ListIcon,
  Notifications as ReminderIcon,
  LocalShipping as AssetsIcon,
  AttachMoney as BillingIcon,
  Block as VacationIcon,
  DoubleArrow as Forward2Icon,
  Event as CalendarIcon, PlayArrow as PlayIcon, OpenInNew as OpenInNewIcon } from '@material-ui/icons';
import { differenceInDays, format, parse, getDay, addHours, eachDayOfInterval, addDays, addMonths, set, getYear, getMonth, getDate, isTomorrow, isToday, endOfDay, startOfDay, isSameDay, parseISO, endOfWeek, startOfMonth, endOfMonth, startOfWeek } from 'date-fns';
import classNames from 'classnames';
import _, { chunk, findLastKey } from 'lodash';
import { DragDropContext } from 'react-beautiful-dnd';
import { useLog } from 'src/kiska/components/contexts/LogContext';
import { gql, useMutation, useQuery } from '@apollo/client';
import { fragments } from 'src/schema/fragments';
import EditIcon from 'mdi-material-ui/Pencil';
import NodeSelector from 'src/kiska/components/NodeSelector';
import { useHistory } from 'react-router-dom';
import { Grid } from 'src/kiska/components/Grid';
import { useNodes } from 'src/kiska/hooks/useNode';
import { Day } from './Day';
import { EventUpdateForm } from './EventUpdateForm';
import { Event } from './Event';
import { JobUpdateForm } from '../job/JobUpdateForm';
import { StartJobButton } from '../job/JobSwitcher/StartJobButton';

const eventQueryString = `
query CalendarEvents(
  $firstDate: timestamptz!
  $lastDate: timestamptz!
  $jobIds: [String!]
  $userIds: [String!]
) {
  events: event(
    order_by: [
      { start: asc }
      { user: { displayName: asc } }
      { job: { title: asc } }
    ]
    where: {
      _and: [
        {
          _or: [
            { type: { _in: ["stat-holiday", "other"] } }
            {
              _and: [
                { type: { _eq: "job-work" } }
                { jobId: { _in: $jobIds } }
                { userId: { _in: $userIds } }
              ]
            }
            {
              _and: [
                { type: { _eq: "worker-not-available" } }
                { userId: { _in: $userIds } }
              ]
            }
          ]
        }
        {
          _or: [
            {
              _and: [
                { start: { _gte: $firstDate } }
                { start: { _lte: $lastDate } }
              ]
            }
            {
              _and: [
                { end: { _gte: $firstDate } }
                { end: { _lte: $lastDate } }
              ]
            }
            {
              _and: [
                { start: { _lte: $firstDate } }
                { end: { _gte: $lastDate } }
              ]
            }
          ]
        }
      ]
    }
  ) {
    ${fragments.event.basic}
  }
}

`;
const EVENT_QUERY = gql`${eventQueryString}`;
const EVENT_SUBSCRIPTION = gql`${eventQueryString.replace('query', 'subscription')}`;

const MOVE_EVENT_MUTATION = gql`
  mutation MoveEvent (
    $id: String!
    $start: timestamptz!
    $end: timestamptz!
  ){
    moveEvent: update_event_by_pk(
      pk_columns: {id: $id}
      _set: {
        start: $start
        end: $end
      }
    ) { id start end }
  }
`;

const useStyles = makeStyles((theme) => ({
  root: {
  },
  month: {

  },
  monthHeader: {
    backgroundColor: `hsla(0,0%,0%,10%)`,
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    display: 'flex',
    '& > div': {
      flex: 1,
    },
  },
  week: {
    display: 'flex',
    alignItems: 'stretch',
    alignContent: 'stretch',
  },
  toolbar: {
    padding: theme.spacing(2, 0, 1, 0),
    display: 'grid',
    // gridTemplateColumns: `auto`,
    gridTemplateColumns: `100px auto 100px`,
    justifyItems: 'stretch',
    alignItems: 'center',
  },
  monthSelector: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bottomToolbar: {
    padding: theme.spacing(4, 0, 0, 0),
    maxWidth: 400,
  },
  userSelector: {
    width: '100%',
    maxWidth: 400,
  },
  list: {
    padding: theme.spacing(2, 0, 0, 0),
  },
  listDay: {
    padding: theme.spacing(1, 0, 1, 0),
    borderBottom: `solid 1px ${theme.palette.border.strong}`,
  },
  rightButtons: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
  },
  legend: {
    display: 'flex',
    justifyContent: 'flex-start',
    flexDirection: 'row',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0, 0, 0),
    '& > div': {
      color: '#FFF',
      margin: theme.spacing(0, 1, 0, 0),
      padding: theme.spacing(0, 1),
      borderRadius: 4,
    },
  },
  startJobButton: {
    borderRadius: 0,
    boxShadow: 'none',
  },
  bottomToolbarWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  assetFilterWrapper: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));

const changeDay = (date, source) => {
  const newDate = set(date, { year: getYear(source), month: getMonth(source), date: getDate(source) });
  return newDate;
};

const getCalendarDates = (referenceDate, { startOfWeekDay, view, dayCount }) => {
  let firstDate;
  let lastDate;
  if (view === 'month') {
    firstDate = startOfWeek(startOfMonth(referenceDate), { weekStartsOn: startOfWeekDay });
    lastDate = endOfWeek(endOfMonth(referenceDate), { weekStartsOn: startOfWeekDay });
  } else if (view === 'list') {
    firstDate = referenceDate;
    lastDate = endOfDay(addDays(firstDate, dayCount));
  } else {
    console.error(`Invalid view "${view}"`);
  }
  const dates = eachDayOfInterval({ start: firstDate, end: lastDate });
  const weeks = chunk(dates, 7);
  const firstWeekDates = eachDayOfInterval({ start: firstDate, end: endOfWeek(firstDate) });

  return { firstDate, lastDate, firstWeekDates, weeks, dates };
};

const Calendar = (props) => {
  const startOfWeekDay = 0;
  const defaultDuration = 1;
  const classes = useStyles(props);
  const { className, job, initialView, showUserFilter, initialUsers, dayCount, showAddJobButton } = props;
  const [eventTypes, setEventTypes] = useState(['job-work', 'worker-not-available']);
  const [view, setView] = useState(initialView);
  const [referenceDate, setReferenceDate] = useState(startOfDay(new Date()));
  const [activeUpdateEvent, setActiveUpdateEvent] = useState(undefined);
  const [activeUpdateDate, setActiveUpdateDate] = useState(undefined);
  const [hoverDate, setHoverDate] = useState(null);
  const [hoverEvent, setHoverEvent] = useState(null);
  const [moveEventMutate] = useMutation(MOVE_EVENT_MUTATION);
  const log = useLog();
  const [users, setUsers] = useState(initialUsers);
  const theme = useTheme();
  const sm = useMediaQuery(theme.breakpoints.down('sm'), { noSsr: true });
  const xs = useMediaQuery(theme.breakpoints.down('xs'), { noSsr: true });
  const [eventMenuData, setEventMenuData] = useState(null);
  const history = useHistory();
  const { nodes: portaJobs } = useNodes({
    type: 'job',
    where: {
      type: { _eq: 'porta-potty' },
      status: { _neq: 'archived' },
    },
    selections: `id createdAt title`,
  });

  const { firstDate, lastDate, firstWeekDates, weeks, dates } = useMemo(() => getCalendarDates(referenceDate, { startOfWeekDay, view, dayCount }), [dayCount, referenceDate, view]);

  const queryVariables = {
    firstDate,
    lastDate,
    userIds: users && users.length ? users.map((user) => user.id) : undefined,
  };
  const useQueryOptions = {
    variables: queryVariables,
    fetchPolicy: 'cache-and-network',
  };
  const eventQueryResult = useQuery(EVENT_QUERY, useQueryOptions);
  if (eventQueryResult) {
    eventQueryResult.subscribeToMore({
      document: EVENT_SUBSCRIPTION,
      variables: queryVariables,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev;
        return subscriptionData.data;
      },
    });
  }

  const usersWithEvents = {};

  const dbEvents = _.get(eventQueryResult, 'data.events', []).map((event) => {
    const newEvent = {
      ...event,
      start: parseISO(event.start),
      end: parseISO(event.end),
    };

    newEvent.isMultiDay = !isSameDay(newEvent.start, newEvent.end);

    if (event.user) {
      usersWithEvents[event.user.id] = event.user;
    }

    return newEvent;
  }).filter((event) => eventTypes.includes(event.type));

  const billingEvents = useMemo(() => {
    if (!eventTypes.includes('porta-billing')) return [];
    const ret = dates.flatMap((date) => portaJobs.flatMap((portaJob) => {
      const diff = differenceInDays(date, parseISO(portaJob.createdAt)) + 1;
      const modulo = diff % 28;
      if (modulo !== 0 || diff < 1) return [];

      const periodNumber = diff / 28;
      const event = {
        id: `${portaJob.id}_${periodNumber}`,
        start: date,
        end: date,
        allDay: true,
        type: 'porta-billing',
        title: `${portaJob.title}`,
        description: `Billing Period: ${periodNumber}`,
        job: portaJob,
        user: null,
        data: {
          periodNumber,
        },
        isMultiDay: false,
      };
      return event;
    }));
    return ret;
  }, [dates, eventTypes, portaJobs]);

  const events = [...dbEvents, ...billingEvents];

  const handleEventClick = (event, clickEvent) => {
    // "event" is NOT the JS mouse event, it is the Slick Systems/Kiska event object stored in database
    if (['job-work', 'porta-billing'].includes(event.type)) {
      setEventMenuData({
        event,
        anchorEl: clickEvent.target,
      });
    } else {
      setActiveUpdateEvent(event);
    }
  };

  const handleDayClick = (date) => (event) => {
    setActiveUpdateEvent(null);
    setActiveUpdateDate(date);
  };

  const handleUpdateFormClose = (date) => {
    setActiveUpdateEvent(undefined);
    setActiveUpdateDate(undefined);
  };

  const handleMouseEnterDay = (date) => {
    setHoverDate(date);
  };

  const handleMouseLeaveDay = (date) => {
    setHoverDate(null);
  };

  const handleMouseEnterEvent = (event) => {
    setHoverEvent(event);
  };

  const handleMouseLeaveEvent = (event) => {
    setHoverEvent(null);
  };

  const start = activeUpdateDate && parse('09:00:00.0000', 'HH:mm:ss.SSSS', activeUpdateDate);
  const end = activeUpdateDate && addHours(start, defaultDuration);

  const handleDragEnd = (result) => {
    const { destination, draggableId } = result;
    if (!destination || !destination.droppableId) return;

    const event = events.find((e) => e.id === draggableId);
    const newDate = parseISO(destination.droppableId);

    const variables = {
      id: event.id,
      start: changeDay(event.start, newDate),
      end: changeDay(event.end, newDate),
    };

    const optimisticResponse = {
      __typename: 'Mutation',
      moveEvent: {
        __typename: 'event',
        id: variables.id,
        start: variables.start,
        end: variables.end,
      },
    };

    moveEventMutate({ variables, optimisticResponse })
      .then(({ error }) => {
        if (error) {
          log.error('Error moving event: ', { variables, error });
        }
      }).catch((error) => {
        log.error('Error moving event: ', { variables, error });
      });
  };

  const handleUpdateSuccess = (...args) => {
    // console.log(args);
  };

  let viewRangeString;
  if (view === 'month') viewRangeString = format(referenceDate, `MMMM yyyy`);
  if (view === 'list') viewRangeString = null;

  const handleMoveReferenceDate = (amount) => {
    setReferenceDate((value) => {
      if (view === 'month') {
        return addMonths(value, amount);
      }
      return value;
    });
  };

  const handleViewChange = (__, value) => {
    if (!value) return;
    setView(value);
  };

  const handleEventTypesChange = (__, value) => {
    if (!value) return;
    setEventTypes(value);
  };

  const toolbar = (
    <>
      <div className={classes.toolbar}>
        <div>
          {showAddJobButton && (
            <JobUpdateForm
              trigger={<Button variant="contained" color="primary"><AddIcon /><span>Job</span></Button>}
            />
          )}
        </div>
        <div className={classes.monthSelector}>
          {view === 'month' && (
            <>
              <IconButton onClick={() => handleMoveReferenceDate(-1)}><BackIcon /></IconButton>
              <Typography variant="h6">{format(referenceDate, `MMMM`)}</Typography>
              <IconButton onClick={() => handleMoveReferenceDate(1)}><ForwardIcon /></IconButton>
              <div style={{ width: 20 }}>&nbsp;</div>
              <IconButton onClick={() => handleMoveReferenceDate(-12)}><BackIcon /></IconButton>
              <Typography variant="h6">{format(referenceDate, `yyyy`)}</Typography>
              <IconButton onClick={() => handleMoveReferenceDate(12)}><ForwardIcon /></IconButton>
            </>
          )}
        </div>
        <div className={classes.rightButtons}>
          <ToggleButtonGroup value={view} onChange={handleViewChange} exclusive>
            <ToggleButton variant="outlined" value="list" size="small"><ListIcon /></ToggleButton>
            <ToggleButton variant="outlined" value="month" size="small"><CalendarIcon /></ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
    </>
  );

  const bottomToolbar = (
    <Grid container spacing={0} justify="space-between" className={classes.bottomToolbarWrapper}>
      <Grid container item spacing={1} xs={12} sm={12} md={6} lg={6} className={classes.assetFilterWrapper}>
        <Grid item xs={12} sm={12} md={12} className={classes.bottomToolbar}>
          {showUserFilter && (
            <div className={classes.userSelector}>
              <NodeSelector type="user" onChange={setUsers} isMulti label="Filter by Asset" value={users} jumpAround={false} />
            </div>
          )}
        </Grid>
        <Grid item xs={12} sm={12} md={12} className={classes.legend}>
          {_.map(usersWithEvents, (user) => (
            <div key={user.id} style={{ backgroundColor: user.preferences.calendarColor || '#888' }}>
              <Typography variant="body2" color="inherit">{user.displayName}</Typography>
            </div>
          ))}
        </Grid>
      </Grid>

      <Grid container spacing={0} justify="flex-end" alignItems="center" item xs={12} sm={12} md={6} lg={6}>
        <ToggleButtonGroup value={eventTypes} onChange={handleEventTypesChange}>
          <ToggleButton variant="outlined" value="job-work" size="small"><AssetsIcon />&nbsp;Assets</ToggleButton>
          <ToggleButton variant="outlined" value="worker-not-available" size="small"><VacationIcon />&nbsp;Unavailable</ToggleButton>
          <ToggleButton variant="outlined" value="porta-billing" size="small"><BillingIcon />&nbsp;Porta Billing</ToggleButton>
          <ToggleButton variant="outlined" value="other" size="small"><ReminderIcon />&nbsp;Reminders</ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );

  const eventUpdateForm = (
    <EventUpdateForm
      open={activeUpdateEvent !== undefined}
      id={activeUpdateEvent && activeUpdateEvent.id}
      initialValues={{ job, start, end, type: 'job-work' }}
      type="job-work"
      onClose={handleUpdateFormClose}
      defaultDuration={defaultDuration}
      onSuccess={handleUpdateSuccess}
    />
  );

  if (view === 'list') {
    return (
      <>
        {eventUpdateForm}
        <div className={classes.list}>
          {toolbar}
          {bottomToolbar}
          {dates.map((date) => {
            const thisDaysEvents = events.filter((event) => {
              if (isSameDay(event.start, date) || isSameDay(event.end, date)) return true;
              if (date >= event.start && date <= event.end) return true;
              return false;
            });

            let dayLabel;
            if (isToday(date)) dayLabel = `Today`;
            else if (isTomorrow(date)) dayLabel = `Tomorrow`;
            else dayLabel = format(date, `EEEE MMM d`);

            return (
              <div className={classes.listDay} key={date.getTime()}>
                <Typography variant="h6" color="textSecondary" align="left">
                  {dayLabel}
                </Typography>
                {!!thisDaysEvents.length && thisDaysEvents.map((event) => (
                  <Event
                    key={event.id}
                    event={event}
                    date={date}
                    onClick={handleEventClick}
                    onMouseEnter={handleMouseEnterEvent}
                    onMouseLeave={handleMouseLeaveEvent}
                    isDraggable={false}
                    isFirstDayInRow={true}
                  />
                ))}
                {!thisDaysEvents.length && (
                  <div className={classes.emptyListDay}>
                    {/* <Typography variant="body1" color="textSecondary">Nothing today</Typography> */}
                  </div>

                )}
              </div>
            );
          })}
          {bottomToolbar}
        </div>
      </>
    );
  }

  const closeEventMenu = () => {
    setEventMenuData(null);
  };

  const handleViewJob = () => {
    history.push(`/app/jobs/${eventMenuData.event.job.id}/details`);
  };

  const handleStartWorking = () => {
    closeEventMenu();
  };

  const handleEditEvent = () => {
    setActiveUpdateEvent(eventMenuData.event);
    closeEventMenu();
  };

  return (
    <>
      {eventUpdateForm}
      <Menu anchorEl={eventMenuData && eventMenuData.anchorEl} open={!!eventMenuData} onClose={closeEventMenu}>
        <MenuItem onClick={handleViewJob}><ListItemIcon><OpenInNewIcon /></ListItemIcon><ListItemText>View Job</ListItemText></MenuItem>
        {/* <MenuItem onClick={handleStartWorking}><ListItemIcon><PlayIcon style={{ color: green[500] }} /></ListItemIcon><ListItemText>Start Working on Job</ListItemText></MenuItem> */}
        {eventMenuData && eventMenuData.event.type === 'job-work' && <StartJobButton job={eventMenuData.event.job} className={classes.startJobButton} />}
        {eventMenuData && eventMenuData.event.type !== 'porta-billing' && <MenuItem onClick={handleEditEvent}><ListItemIcon><EditIcon /></ListItemIcon><ListItemText>Edit Event</ListItemText></MenuItem>}
      </Menu>
      <DragDropContext onDragEnd={handleDragEnd}>
        <div className={classNames(className, classes.root)}>

          {toolbar}
          {bottomToolbar}

          <div className={classes.month}>
            <div className={classes.monthHeader}>
              {firstWeekDates.map((date) => {
                const hover = hoverDate && (getDay(date) === getDay(hoverDate));
                return (
                  <div key={date}>
                    <Typography
                      variant="overline"
                      align="center"
                      component="div"
                      color={hover ? 'textPrimary' : 'textSecondary'}
                      style={{ fontWeight: hover ? 700 : 400 }}
                    >
                      {format(date, xs ? `EEE` : `EEEE`)}
                    </Typography>
                  </div>
                );
              })}
            </div>

            {weeks.map((week, weekIndex) => (
              <div className={classes.week} key={week[0]}>
                {week.map((date, dateIndex) => {
                  return (
                    <Day
                      key={date}
                      date={date}
                      isBottomRow={weekIndex === weeks.length - 1}
                      onClick={handleDayClick(date)}
                      events={events}
                      onEventClick={handleEventClick}
                      onMouseEnter={handleMouseEnterDay}
                      onMouseLeave={handleMouseLeaveDay}
                      onMouseEnterEvent={handleMouseEnterEvent}
                      onMouseLeaveEvent={handleMouseLeaveEvent}
                      job={job}
                      hoverEvent={hoverEvent}
                      isFirstInRow={dateIndex === 0}
                    />
                  );
                })}
              </div>
            ))}

          </div>
          <br />
          {bottomToolbar}

        </div>
      </DragDropContext>
    </>
  );
};

Calendar.propTypes = {
  initialView: PropTypes.string,
  showUserFilter: PropTypes.bool,
  initialUsers: PropTypes.array,
  dayCount: PropTypes.number,
  showAddJobButton: PropTypes.bool,
};
Calendar.defaultProps = {
  initialView: 'month',
  showUserFilter: true,
  initialUsers: [],
  dayCount: 14,
  showAddJobButton: true,
};

export { Calendar };
