import React from 'react';
// import PropTypes from 'prop-types';
import { Typography } from '@material-ui/core';
import { Grid } from 'src/kiska/components/Grid';
import { makeStyles } from '@material-ui/core/styles';
import { Add as AddIcon } from '@material-ui/icons';
import EditIcon from 'mdi-material-ui/Pencil';

import { FormList } from 'src/components/dashboards/FormList';
import { StartNewFormList } from '../modules/form/StartNewFormList';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    padding: theme.spacing(4),
  },
}));

const Forms = () => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Grid container spacing={4} justify="center">
        <Grid item xs={12} md={6}>
          <Typography variant="h4" color="textSecondary" align="center" gutterBottom>
            <AddIcon /> Start New Form
          </Typography>
          <StartNewFormList />
        </Grid>
        <Grid item xs={12} md={6} container justify="center">
          <Typography variant="h4" color="textSecondary" align="center" gutterBottom>
            <EditIcon /> Existing Forms
          </Typography>
          {/* <FormList /> */}
        </Grid>
      </Grid>
    </div>
  );
};

Forms.propTypes = {
};
Forms.defaultProps = {
};

export { Forms };
